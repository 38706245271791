


















































































































































































































































































































































































































































































































































































































































import { Vue, Component } from 'vue-class-decorator'
import { GlobalModule, ClientsModule, UserModule } from '@/store'
import { Payload } from 'vue/interfaces'
import { Comment } from 'vue/types'
import { clientsStatusesSelect, clientsStatusesArray, clientsTypes, clientsUnique, clientsSource } from '@/utilities'

@Component({
  metaInfo: {
    title: 'Просмотр клиента'
  },
  components: {
    Comments: () => import('@/components/pages/Comments.vue')
  }
})
export default class EditPrices extends Vue {
  public clientId: string
  public deleteDialog: boolean
  public becomeDemonstatorDialog: boolean
  public addFavouriteDialog: boolean
  public changeStatusDialog: boolean
  public statusDialogHint: string
  public statusFailComment: string

  // Page data
  public users: Array<{[propName: string]: any}>
  public types: Array<{[propName: string]: any}>
  public managers: Array<{[propName: string]: any}>
  public client: Array<{[propName: string]: any}> | any
  public oldClient: Array<{[propName: string]: any}> | any
  public clientsTypes: Array<{[propName: string]: any}>
  public clientsUnique: Array<{[propName: string]: any}>
  public clientsSource: Array<{[propName: string]: any}>
  public selectStatuses: Array<object>
  public callsTableHeaders: Array<object>
  public pricesTableHeaders: Array<object>
  public calls: Payload
  public prices: any
  public allPrices: any
  public allPricesAvailable: any

  public addFavouriteSelectedPrice: any

  public becomeDemonstratorClientType: number
  public becomeDemonstratorComment: string
  public becomeDemonstratorPriceId: any
  public becomeDemonstratorShowDatetime: any
  public becomeDemonstratorStatusDatetime: any

  // Comments
  public comments: Array<Comment>

  // Logs
  public logs: Payload
  public paginationLogsPage: number

  constructor() {
    super()
    this.clientsTypes = clientsTypes
    this.clientsUnique = clientsUnique
    this.clientsSource = clientsSource
    this.deleteDialog = false
    this.becomeDemonstatorDialog = false
    this.addFavouriteDialog = false
    this.changeStatusDialog = false
    this.statusDialogHint = 'Дата статуса'
    this.statusFailComment = ''
    this.clientId = '0'
    this.client = {}
    this.oldClient = {}

    this.becomeDemonstratorClientType = 1
    this.becomeDemonstratorComment = ''
    this.becomeDemonstratorPriceId = null
    this.addFavouriteSelectedPrice = null
    this.becomeDemonstratorShowDatetime = null
    this.becomeDemonstratorStatusDatetime = new Date()

    // Page data
    this.users = []
    this.managers = []
    this.types = []
    this.logs = []
    this.prices = []
    this.allPrices = []
    this.allPricesAvailable = []
    this.calls = []

    // Logs
    this.paginationLogsPage = 1

    // Comments
    this.comments = []

    // Consts
    this.selectStatuses = clientsStatusesSelect
    this.pricesTableHeaders = [
      {
        text: 'ID',
        value: 'price_id',
        align: 'start',
        sortable: false
      },
      {
        text: 'Название',
        value: 'name',
        sortable: false
      },
      {
        text: 'Цена',
        value: 'price',
        sortable: false
      },
      {
        text: 'Опции',
        value: 'actions',
        sortable: false
      }
    ]
    this.callsTableHeaders = [
      {
        text: 'ID',
        value: 'call_id',
        align: 'start',
        sortable: false
      },
      {
        text: 'Тип',
        value: 'direction',
        sortable: false
      },
      {
        text: 'От кого',
        value: 'phone_from',
        sortable: false
      },
      {
        text: 'Кому',
        value: 'phone_to',
        sortable: false
      },
      {
        text: 'Дата',
        value: 'date',
        sortable: false
      },
      {
        text: 'Запись',
        value: 'file_url',
        sortable: false
      }
    ]
  }

  // Store init
  @GlobalModule.Getter('getLoading') loading!: boolean
  @UserModule.Getter('userInfo') userInfo!: { [propName: string]: any }
  @ClientsModule.Action('getClientPageData') getPageData!: (id: number | string) => Promise<any>
  @ClientsModule.Action('updateClient') updateClient!: (payload: Payload) => Promise<void>
  @ClientsModule.Action('deleteClient') deleteClient!: (id: number | string) => Promise<void>
  @ClientsModule.Action('changeFavourite') changeFavouriteStore!: (payload: {clientId: string | number; priceId: string | number}) => Promise<void>

  get paginationLogsLength() {
    return Math.ceil(this.logs.length / 5)
  }

  get paginationLogs() {
    const start = (this.paginationLogsPage - 1) * 5
    return this.logs.slice(start, start + 5)
  }

  get statusText() {
    return clientsStatusesArray[this.client.status]
  }

  get statusDialog() {
    return new Date()
  }

  set statusDialog(datetime) {
    // eslint-disable-next-line
    this.client.status_date = (new Date (
      (new Date(
        (new Date(datetime)).toISOString()
      )).getTime() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 19).replace('T', ' ')
  }

  get isAdmin() {
    return this.userInfo?.group?.group_id && (this.userInfo.group.group_id === 1 || this.userInfo.group.group_id === 6)
  }

  get isDemonstrator() {
    return this.userInfo?.group?.group_id && this.userInfo.group.group_id === 4
  }

  get addFavouritePrices() {
    return this.allPricesAvailable.filter((price) => {
      for (const localPrice of this.prices) {
        // eslint-disable-next-line
        if (localPrice.price_id == price.price_id) {
          return false
        }
      }

      return true
    })
  }

  get showDate() {
    return new Date(this.client.show_date)
  }

  set showDate(datetime) {
    // eslint-disable-next-line
    this.client.show_date = (new Date (
      (new Date(
        (new Date(datetime)).toISOString()
      )).getTime() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 19).replace('T', ' ')
  }

  goToPrice(priceId: number | string) {
    this.$router.push('/prices/edit/' + priceId)
  }

  addFavourite() {
    if (!this.addFavouriteSelectedPrice?.price_id) {
      return this.$noty('error', 'Объект не выбран', 7000).show()
    }

    this.changeFavourite(this.addFavouriteSelectedPrice.price_id)
  }

  changeFavourite(priceId: number | string) {
    this.changeFavouriteStore({ clientId: this.clientId, priceId })
      .then(() => {
        this.addFavouriteDialog = false
        this.loadPageData()
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  formatPrice(price: number) {
    return (new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency: 'RUB'
    })).format(price)
  }

  acceptDelete() {
    this.deleteDialog = false
    this.deleteClient(this.clientId)
      .then(() => {
        this.$noty('success', 'Клиент удален!', 5000).show()
        this.$router.push('/clients/')
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  acceptChangeStatus() {
    if (this.client.status === 7) {
      if (this.client.comment && this.client.comment.length > 0) {
        this.client.comment += ('\nПричина отказа: ' + this.statusFailComment + '\nСо статуса: ' + clientsStatusesArray[this.oldClient.status])
      } else {
        this.client.comment = ('Причина отказа: ' + this.statusFailComment + '\nСо статуса: ' + clientsStatusesArray[this.oldClient.status])
      }
    }

    // eslint-disable-next-line
    const newClient = this.client.price_id ? { ...this.client, price_id: this.client.price_id.price_id } : this.client
    this.updateClient(newClient)
      .then(() => {
        this.changeStatusDialog = false
        this.$noty('success', 'Клиент сохранен!', 5000).show()
        this.loadPageData()
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  handleStatus() {
    const hints = [
      'Дата создания',
      'Дата обработки',
      'Дата смены статуса',
      'Дата совершённого показа',
      'Дата одобрения ипотеки',
      'Дата внесения аванса',
      'Дата выезда',
      'Дата отказа'
    ]

    if ([2, 5, 6].includes(this.client.status)) {
      // eslint-disable-next-line
      this.client.price_id = null
    }

    this.statusDialogHint = hints[this.client.status]
    this.changeStatusDialog = true
  }

  becomeDemonstartor() {
    if (!this.userInfo?.user_id) {
      return this.$noty('error', 'Не получилось идентифицировать Вас как пользователя.', 7000).show()
    }

    let newClient = {}
    if (this.becomeDemonstratorClientType === 1) {
      if (!this.becomeDemonstratorComment) {
        return this.$noty('error', 'Вы не указали особые отметки.', 7000).show()
      }

      // eslint-disable-next-line
      const statusDate = (new Date (
        (new Date(
          (new Date(this.becomeDemonstratorStatusDatetime)).toISOString()
        )).getTime() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 19).replace('T', ' ')

      // eslint-disable-next-line
      newClient = { ...this.client, demonstrator_id: this.userInfo.user_id, status: this.becomeDemonstratorClientType, status_date: statusDate, comment: this.becomeDemonstratorComment }
    } else if (this.becomeDemonstratorClientType === 2) {
      if (!this.becomeDemonstratorShowDatetime) {
        return this.$noty('error', 'Вы не указали дату показа.', 7000).show()
      }

      if (!this.becomeDemonstratorPriceId || !this.becomeDemonstratorPriceId.price_id) {
        return this.$noty('error', 'Вы не указали объект.', 7000).show()
      }

      // eslint-disable-next-line
      const statusDate = (new Date (
        (new Date(
          (new Date(this.becomeDemonstratorStatusDatetime)).toISOString()
        )).getTime() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 19).replace('T', ' ')

      // eslint-disable-next-line
      const showDate = (new Date (
        (new Date(
          (new Date(this.becomeDemonstratorShowDatetime)).toISOString()
        )).getTime() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 19).replace('T', ' ')
      console.log(this.becomeDemonstratorPriceId)
      // eslint-disable-next-line
      newClient = { ...this.client, price_id: this.becomeDemonstratorPriceId.price_id, demonstrator_id: this.userInfo.user_id, status: this.becomeDemonstratorClientType, status_date: statusDate, show_date: showDate }
    }

    this.updateClient(newClient)
      .then(() => {
        this.becomeDemonstatorDialog = false
        this.$noty('success', 'Клиент обновлён!', 5000).show()
        this.loadPageData()
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  saveClient() {
    if (!this.client.phones) {
      return this.$noty('error', 'Заполните номер телефона!', 7000).show()
    }

    if (this.oldClient.status !== this.client.status && this.oldClient.status === 0) {
      if (!this.client.firstname || !this.client.client_type || !this.client.comment) {
        return this.$noty('error', 'Для смены статуса заполните поля: Имя, Телефон, Тип клиента, Особые отметки.', 7000).show()
      }
    }

    if (this.oldClient.status !== this.client.status) {
      return this.handleStatus()
    }

    this.updateClient(this.client)
      .then(() => {
        this.$noty('success', 'Клиент сохранен!', 5000).show()
        this.loadPageData()
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  loadPageData() {
    this.getPageData(this.clientId)
      .then((res) => {
        this.oldClient = {
          ...res.client,
          emails: res.client.emails ? res.client.emails.join(', ') : null,
          phones: res.client.phones ? res.client.phones.join(', ') : null
        }
        this.client = {
          ...res.client,
          emails: res.client.emails ? res.client.emails.join(', ') : null,
          phones: res.client.phones ? res.client.phones.join(', ') : null
        }
        this.becomeDemonstratorComment = res.client.comment
        // eslint-disable-next-line
        this.managers = [{ user_id: 0, title: 'Не указано' }, ...res.managers]
        this.comments = res.comments
        this.calls = res.calls
        this.prices = res.prices
        this.logs = res.logs
        this.allPrices = res.all_prices
        this.allPricesAvailable = res.all_prices.filter((price) => [1, '1', 7, '7'].includes(price.object_status))
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
        this.$router.push('/clients')
      })
  }

  created() {
    this.clientId = this.$route.params.id
    this.loadPageData()
  }
}
